<template>
  <div v-if="pricing" id="home">
    <div v-if="errorMessage" class="error-banner">
      <h1 class="error">{{errorMessage}}</h1>
    </div>
    <SubscriptionPlanChanger v-if="!successfulPayment" @changedPlanType='changedPlanType' @checkoutSubscription="checkoutSubscription" @resetToCheckout="resetToCheckout" v-bind:pricingTiers="pricing"/>
    <StripePayment v-if="!successfulPayment && step == 2" v-bind:customerId="customerId" v-bind:upgradeInfo="upgradeInfo" @paid="successfullyPaid"/>
    
    <div v-if="successfulPayment" class="component card">
      <div class="title">
        <h1>All set!</h1>
        <p>You successfully changed subscription tiers to {{upgradeInfo.next_tier.name}}.</p>
        <router-link to="/dashboard" tag="button" class="big-button">Back to the dashboard</router-link>
      </div>
    </div>
  </div>
  <div v-else class="loader">
  </div>
</template>

<script>
import SubscriptionPlanChanger from '@/components/SubscriptionPlanChanger.vue';
import StripePayment from '@/components/StripePayment.vue';

import axios from 'axios';


var truth = {
  step: 1,
  pricing: null,
  stripeClientSecret: null,
  successfulPayment: null,
  errorMessage: null,
  upgradeInfo: null,
}

export default {
  name: 'ChangeSubscription',
  components: {
    SubscriptionPlanChanger,
    StripePayment,
  },
  data() {
    return truth;
  },
  created() {
    axios.get('pricing/').then((response) => {
      this.pricing = response.data.pricing;
      console.log("Got pricing");
    }).catch((error) => {
      this.errorMessage = error.response.data.error;
    });
  },
  methods: {
    changedPlanType: function () {
      console.log("Changed plan type");
      this.stripeClientSecret = null;
      this.subscriptionTier = null;
    },
    checkoutSubscription: function (upgradeInfo) {
      console.log("Before: ", upgradeInfo.next_tier.id);
      this.upgradeInfo = upgradeInfo;
      console.log("After: ", this.upgradeInfo.next_tier.id);
      this.step = 1;
      this.step = 2;
    },
    resetToCheckout: function() {
      console.log("Resetting");
      this.step = 1;
      this.upgradeInfo = null;
    },
    successfullyPaid: function () {
      this.successfulPayment = true;
    },
  },
  computed: {
    customerId: function() {
      return this.$store.state.stripeAccount.id;
    },
    subscription: function() {
      return this.$store.state.stripeAccount.subscription;
    },    
  }
};


</script>

<style scoped lang="scss">
#home {
  margin-top: 125px;
}
.error-banner {
  background: red;
}
</style>

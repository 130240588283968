<template>
  <div class="component card" id="checkout">
    <div v-if="hasActiveSubscription">
      <div id="plans">
        <div class="title">
          <h1>Change your subscription</h1>
          <p>You're currently on the {{ subscription.plan_info.name }} plan, and you have {{ subscription.remaining_alerts }} alerts left.</p>
        </div>
        <div class="plan-types">
          <plan-item
            v-for="plan in plans"
            v-bind:key="plan.id"
            v-bind:plan="plan"
            @selectPlan="selectPlan"
            :isSelected="selectedPlan===plan">
          </plan-item>
        </div>
      </div>
      <div id="tiers" v-if="selectedPlan">
        <div class="title">
          <h1>Pick your new tier</h1>
          <p>Not seeing an option you'd like, or have you got any questions or concerns? I'm here to help. Just throw me an email: <a href="mailto:andrew@bottlebeagle.com">andrew@bottlebeagle.com</a></p>
        </div>
        <div v-if="pricingTiers" >
          <div class="pricing-tiers">
            <tier-item
              v-for="tier in tiersForPlan"
              v-bind:key="tier.id"
              v-bind:tier="tier"
              @selectTier="selectTier"
              :isSelected="selectedTier===tier"
              :isCurrent="tier.id===subscription.plan_info.id"
              ></tier-item>
          </div>
        </div>
        <p v-if="errorMsg" class="error">{{errorMsg}}</p>
        <div v-if="loadingCheckoutAlert" class="loader"></div>
      </div>
    </div>
    <div v-else class="loader"></div>
  </div>
</template>

<script>
import axios from 'axios';
import TierItem from '@/components/TierItem.vue';
import PlanItem from '@/components/PlanItem.vue';



export default {
  name: 'SubscriptionPlanChanger',
  props: ["pricingTiers", "coordinates", "radius", "upgradeInfo"],
  components: {"tier-item": TierItem, "plan-item": PlanItem},
  data() {
    return {
      selectedPlan: null,
      selectedTier: null,
      errorMsg: null,
      loadingCheckoutAlert: false,
      plans: [
        {
          title: "Monthly",
          tagline: "For the connoisseur",
          description: "Faster scans, custom filters, and scans that last as long as you want",
          id: "monthly"
        },
        {
          title: "Yearly",
          tagline: "For the savvy",
          description: "Everything in Monthly – plus a discount",
          id: "yearly"
        },
      ],
    };
  },
  mounted() {
    this.scrollToElement();
  },
  methods: {
    selectPlan: function (plan) {
      this.selectedPlan = plan;
      this.selectedTier = null;

      this.$emit('changedPlanType');
    },
    selectTier: function (tier) {
      console.log("Selected tier: ", tier.id);
      this.selectedTier = tier;

      // just emit the checkout event
      console.log("Okay:", this.selectedTier);

      axios.post('pricing/calculate_proration/', 
        {
          price_id: this.selectedTier.id,
          existing_subscription: this.subscription.id
        }).then((response) => {
          let upgradeInfo = response.data;
          this.$emit('checkoutSubscription', upgradeInfo);
          this.errorMsg = null;
      }).catch((error) => {
        this.errorMsg = error.response.data.error;
        this.$emit('resetToCheckout');
      });
    }
  },
  computed: {
    hasActiveSubscription () {
      if (this.$store.state.stripeAccount.subscription) {
        return true;
      }
      return false;
    },
    subscription () {
      return this.$store.state.stripeAccount.subscription;
    },
    prorationPrice () {
      let priceInDollars = this.upgradeInfo.amount / 100;
      return "$"+ priceInDollars.toFixed(2);
    },
    tiersForPlan () {
      return this.pricingTiers[this.selectedPlan.id];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">



#plans {
  margin-bottom: 1.5em;
  .plan-types {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 2em;
  }
}

#tiers {
  .pricing-tiers {
    display: flex;
    flex-flow: column nowrap;
    // padding: 1em;
    // padding: 0.5em 3em 3em 0.5em;
    // height: 120%;
    // width: 100%;
    padding: 1em;

    .tier {
      max-width: 420px;
      width: 100%;
      margin: 0.5em auto;
      border-radius: 8px;
    }
  }
}



@media only screen and (max-width: 900px) {
  #plans {
    .plan-types {
      margin-top: 1em;
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }
  }
  #checkout {
    .pricing-tiers {
      padding: 1px;
      margin: 0 auto;
      // padding: 1em;
      align-items: center;
      .tier {
        // min-width: 260px;
        width: calc(100% - 6em);
        padding: 1.5em 1em;
      }

    }

  }
}


</style>
